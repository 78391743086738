.Media {
    display: inline-block;
    position: relative;
    
    &.editable {
        cursor: pointer;
    }

    .logoContainer {
        border: 1px solid black;
        width: 120px;
        height: 120px;
        margin-bottom: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

        img {
            object-fit: contain;
            object-position: center;
            width: 100%;
            height: 100%;

            &.error {
                display: none;
            }
        }

        .check {
            position: absolute;
            top: 15px;
            left: 15px;
        }
    }

  .Media-remove-icon {
    cursor: pointer;
    position: absolute;
    bottom: 10px;
    right: 10px;

    .fa {
      font-size: 14px;
      color: red;
    }
  }
  .Media-remove-icon:hover {
    .fa {
      font-size: 14px;
    }
  }
}
