.menu-item {
  .badge {
    background-color: rgb(23, 193, 145);
    color: white;
    border-radius: 50%;
    line-height: 1;
    font-weight: 700;
    font-size: 14px;
    width: 26px;
    height: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
