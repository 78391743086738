.MuiTableCell-root {
  font-size: 1em!important;
}

#kt_content {
  padding-top: 10px;
}

.tab-content {
  flex-shrink: 1;
  min-height: 0;

  .tab-pane {
    max-height: 100%;
    display: flex!important;
    flex-direction: column;
  }

  
  .fade:not(.show) {
    display: none!important;
  }

  .MuiTableContainer-root {
    flex-shrink: 1;
    min-height: 0;
  }
}
