.Order {
  .tab-content {
    flex-grow: 1;
    flex-shrink: 1;
    min-height: 0;
  }
  .tab-pane {
    height: 100%;
  }

  .MuiListItem-container {
    display: flex;
    flex-direction: row;
  }
}
