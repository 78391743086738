.Media2 {
  width: 185px;
  height: 202px;

  &.compact {
    width: 100px;
    height: 100px;

    .Media-thumbnail {
      width: 100px;
      height: 100px;
    }
  }

  .Media-thumbnail {
    width: 185px;
    height: 172px;
    border: 1px solid #1b1c1d;
    position: relative;
    background-color: #eee;

    &.selected {
      transition: border 0.2s cubic-bezier(0.215, 0.610, 0.355, 1.000);
      border: 4px solid #2185d0;
      opacity: 0.6;
    }

    .uploadIndicator {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: 16px;
      color: black;

      div:first-child {
        margin-bottom: 5px;
      }
    }

    .Media-thumbnail-image {
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      width: 100%;
      height: 100%;
    }
    video {
      width: 186px;
      height: 104px;
      border-radius: 5px;
      top: 50%;
      position: absolute;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
    }

    .Media-remove-icon {
      cursor: pointer;
      position: absolute;
      bottom: 10px;
      right: 10px;

      .fa {
        font-size: 14px;
        color: red;
      }
    }
    .Media-remove-icon:hover {
      .fa {
        font-size: 14px;
      }
    }
  }

  .Media-name {
    width: 100%;
    margin-top: 8px;
    font-size: 13px;
    color: #111;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.Media-grab {
  cursor: grab;
}
.Card-pointer {
  Media: pointer;
}

.Media:hover {
  .Media-remove-icon {
    opacity: 1;
  }
}

.Media.inverted {
  .Media-name {
    color: white;
  }
}
