.Products {
  .dropdown-menu {
    &.open {
      display: block!important;
    }
  }

  .card-header-tabs {
    margin: 0;
  }
  .tab-content {
    padding: 5px;
    padding-top: 10px;
    border-top: 1px solid rgb(229, 234, 238);
  }

  .media-gallery {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .Media {
      margin: 10px;
    }
  }
}