td {
  a {
    margin: 5px;
  }
}

input {
  margin-bottom: 5px;
}


.toolbar {
  button {
    margin-left: 5px;
    margin-right: 5px;
  }
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.media-gallery {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .Media2 {
    margin: 10px;
  }
}
