.HomeGrid {
  max-width: 100%;
  max-height: 100%;
  flex-wrap: nowrap!important;

  @media (max-width: 720px) {
    flex-wrap: wrap!important;
  }

  .ArticleCard {
    max-width: 50%;
    flex-grow: 1;

    @media (max-width: 1080px) {
      max-width: 100%;
    }

    .MuiCard-root {
      height: 600px;

      @media (max-width: 1080px) {
        height: 450px;

        /*.MuiCardMedia-root {
          background-size: cover!important;
          max-height: 150px!important;
        }*/
      }
    }
  }

  .meetingItem {
    overflow-x: hidden;

    .MuiListItem-container {
      display: flex;
      flex-direction: row!important;
      align-items: center;
      width: 100%;
      padding: 8px;
    }

    .MuiListItem-root {
      flex-grow: 1;
      flex-shrink: 1;
      min-width: 0px;
      padding: 0;
      padding-right: 8px;
    }

    .MuiListItemSecondaryAction-root {
      top: unset;
      right: unset;
      position: unset;
      transform: none;
      flex-grow: 0;
      flex-shrink: 0;
    }
  }
}

.BottomBar {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;

  .MuiButtonBase-root {
    outline: none!important;
  }

  @media (min-width: 721px) {
    display: none!important;
  }
}
