.ArticleContent {
  img {
    max-width: 100%;
    height: auto;
  }

  &.right {
    text-align: right;
    width: 100%;
  }
}

.right {
  flex-direction: row-reverse;

  .MuiCardHeader-content {
    flex: unset;
  }
  .MuiCardHeader-avatar {
    margin-right: unset;
    margin-left: 16px;
  }
}

.MuiCard-root {
  margin-top: 16px;

  &.right {
    width: 500px;
    max-width: 100%;
    align-self: flex-end;
  }
}
  
