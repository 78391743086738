.Meetings {
  .card-header-tabs {
    margin: 0;
  }
  .tab-content {
    padding: 5px;
    padding-top: 10px;
    border-top: 1px solid rgb(229, 234, 238);
  }
  .MuiTypography-root {
    margin-bottom: 0.5rem;
  }
}
